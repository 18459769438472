document.addEventListener('click', function (event) {

    let dropLists;
    // Make sure no dropdown item is show when first click the button
    if (!event.target.matches('.dropbtn')) {
        dropLists = document.getElementsByClassName("dropdown-content");
        for (let i of dropLists) {
            i.classList.remove("show");
        }
        return;
    }

    // Get the dropdown list for the click button
    let dropItem = event.target.nextElementSibling; //div containing the dropdown

    // let currentItem = document.getElementById(dropItem["id"]);
    if (dropItem.classList.contains("show")) {

        dropItem.classList.remove("show");
        return;
    }

    dropLists = document.getElementsByClassName("dropdown-content");
    for (let item of dropLists) {
        item.classList.remove("show");
    }

    dropItem.classList.add("show");

}, false);

/*********************************************************************/

// Zoom image in modal window on click
const elements = document.getElementsByClassName("zoom-img");

Array.from(elements).forEach((element) => {
    element.addEventListener('click', function () {
        const attribute = this.getAttribute("src");
        document.getElementById('zoomed-images').classList.add("show");
        const img = document.getElementById('zoomed-img');
        img.setAttribute('src', attribute);
    });
});

// Close zoomed image
document.getElementById('modal-black-bg').addEventListener('click', () => {
    document.getElementById('zoomed-images').classList.remove("show");
});

document.getElementById('close-zoomed').addEventListener('click', () => {
    document.getElementById('zoomed-images').classList.remove("show");
});

/*********************************************************************/

// Track clicks

const links = document.getElementsByTagName("a");

Array.from(links).forEach((item) => {
    item.addEventListener('click', function (event) {

        const target = event.currentTarget;

        if (target.title !== '') {

            event.preventDefault();

            const xhr = new XMLHttpRequest();

            xhr.open('POST', 'https://detarot.com.es/register-click');
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.setRequestHeader('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').content);
            xhr.onload = () => {
                window.location.href = target.href;
            };
            xhr.send(encodeURI('clicked=' + target.title + '&product=' + target.getAttribute('data-product')));
        }
    }, false);
});

/*********************************************************************/

// Replace words with their equivalent with tilde (for SEO purposes)

const words = document.getElementsByClassName('tilde');
for (let word of words) {
    word.textContent = word.dataset.tilde;
}

/*********************************************************************/

// Show cookies panel or switch button as required
if (getCookie('ga_consent_1596132902495')) {

    // The user made a decision previously, show the switch
    document.getElementsByClassName('cookies-switch')[0].classList.add("show");


    if (getCookie('ga_consent_1596132902495') === 'false') {
        // Uncheck
        document.getElementById("google-analytics").checked = false;
    } else {
        // Check
        document.getElementById("google-analytics").checked = true;
        setGAscript();
    }
} else {

    // The user didn't make a decision yet, show the panel
    document.getElementsByClassName('cookies-panel')[0].classList.add("show");
}

// Listen for clicks at the selection cookies confirm button
document.addEventListener('click', function (event) {

    if (event.target.id === 'cookies-accept') {
        document.getElementsByClassName('cookies-panel')[0].classList.remove("show");
        document.getElementsByClassName('cookies-switch')[0].classList.add("show");
        document.getElementById("google-analytics").checked = true;
        setCookie("ga_consent_1596132902495", true);
        setGAscript();
    }

    if (event.target.id === 'cookies-confirm') {
        document.getElementsByClassName('cookies-panel')[0].classList.remove("show");
        document.getElementsByClassName('cookies-switch')[0].classList.add("show");
        //
        if (document.getElementById('google-analytics').checked) {
            setCookie("ga_consent_1596132902495", true);
            setGAscript();
        } else {
            removeCookies();
            setCookie("ga_consent_1596132902495", false);
            // Uncheck
            document.getElementById("google-analytics").checked = false;
        }
    }

    if (event.target.id === 'cookies-config') {
        document.getElementsByClassName('layer-one')[0].classList.remove("show");
        document.getElementsByClassName('layer-two')[0].classList.add("show");
    }
}, false);

// Listen for clicks at the cookies panel switch button
document.addEventListener('click', function (event) {
    if (event.target.id === 'cookies-switch-btn') {
        document.getElementsByClassName('cookies-switch')[0].classList.remove("show");
        document.getElementsByClassName('cookies-panel')[0].classList.add("show");
    }
}, false);

/**
 * @name setCookie
 * @param key
 * @param value
 */
function setCookie(key, value) {
    const expires = new Date();
    expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
}

/**
 * @name getCookie
 * @param key
 * @returns {any}
 */
function getCookie(key) {
    const keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
}

/**
 * @name removeCookies
 */
function removeCookies() {
    const cookiesStr = document.cookie;
    const cookiesArr = cookiesStr.split(";");
    for (let i = 0; i < cookiesArr.length; i++) {
        const cookie = cookiesArr[i].split("=");
        document.cookie = cookie[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
    }
}

/**
 * @name setGAscript
 */
function setGAscript() {
    let script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-10MSXWCZKN";
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];

    function gtag() {
        dataLayer.push(arguments);
    }

    gtag('js', new Date());

    gtag('config', 'G-10MSXWCZKN');
}
